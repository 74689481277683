// import React from 'react';
// import { Chart } from 'react-charts';

function MyChart() {
   return(
    <div>
        </div>
    )
  }

  export default MyChart;
